import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "~/lib/utils";
import { FaSpinner } from "react-icons/fa6";
import { useNavigation } from "@remix-run/react";

const buttonVariants = cva(
  "bg-backgroundComponent text-primary p-2 rounded-lg border cursor-pointer disabled:cursor-not-allowed w-full",
  {
    variants: {
      variant: {
        default: "bg-primary text-white hover:bg-buttonHover",
        icon: "w-8 h-8 rounded-full text-primary cursor-pointer items-center justify-center flex",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  _action?: string;
  loading?: boolean;
}

export default function Button({
  children,
  loading = false,
  disabled = false,
  variant,
  size,
  className,
  ...props
}: ButtonProps) {
  const navigation = useNavigation();

  function isLoading() {
    return (
      loading ||
      (props.name === "_action" &&
        (navigation.state === "submitting" || navigation.state === "loading") &&
        navigation.formData?.get("_action") === props.value)
    );
  }

  return (
    <button
      className={cn(buttonVariants({ variant, size, className }))}
      disabled={isLoading() || disabled}
      {...props}
    >
      <div className="flex items-center gap-2 justify-center">
        {isLoading() && <FaSpinner className="animate-spin" />} {children}
      </div>
    </button>
  );
}
